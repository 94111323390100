import { Box, Grid, Typography } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CBButton from '../components/CBButton';

function ErrorPage() {

    const error = useSelector(state => state.error)
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: 'secondary',
            }}
        >
            <Typography variant="h2" style={{ color: 'white' }}>
                {"OOPS!! " + error?.httpStatus}
            </Typography>
            <Typography variant="h6" style={{ color: 'white', mt:3 }}>
                {error?.message ? error.message : "Something went wrong!"}
            </Typography>
            <CBButton sx={{mt:1}} onClick={() => navigate('/', { replace: true })}>Back Home</CBButton>
        </Box>
    )
}

export default ErrorPage;