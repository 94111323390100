import { Box, CardContent, Typography } from '@mui/material'
import CBTabPanel from '../../../../common/components/CBTabPanel'
import { instructions } from './welcomContent'


const WelcomeTabPanel = () => {
    return (
        <CBTabPanel sx={{ overflow: 'auto' }}>
            <Typography variant="h6" sx={{ my: 1 }}>Welcome 🎉 to CodeBattle!</Typography>
            <Typography variant="body2">Let's get you set up and ready to compete.<br />Follow these instructions to begin your coding journey.</Typography>

            {instructions && instructions.map((instruction, index) => {
                return (
                    <Box key={index}>
                        <Typography variant="body1" fontWeight={700} sx={{ mt: 2, whiteSpace: 'pre-wrap' }}>{instruction?.title}</Typography>
                        <Typography variant="body2" sx={{ mt: 1, whiteSpace: 'pre-wrap' }}>{instruction?.value}</Typography>
                    </Box>
                )
            })}
        </CBTabPanel>
    )
}

export default WelcomeTabPanel
