import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Box, Tooltip, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { useEffect, useState } from "react"
import ActivityCalendar from "react-activity-calendar"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import CBButton from "../../../../common/components/CBButton"
import CBMenu from "../../../../common/components/CBMenu"
import { getUserTimeGraph } from "../../../apis/userApis"
import ProfileCard from "./profileCard"

const TimeGraph = () => {

    const { userTimeGraph } = useSelector(state => state.user)
    const [anchorEl, setAnchorEl] = useState(null);
    const graphYearOptions = Array.from({ length: new Date().getFullYear() - 2023 + 1 }, (_, index) => {
        const year = new Date().getFullYear() - index;
        return {
            value: year.toString(),
            label: year.toString()
        };
    });
    const [graphYear, setGraphYear] = useState(graphYearOptions[0]);

    const timeGraphList = userTimeGraph?.userTimeGraphObjectList || []
    const { uid } = useParams();
    console.log("TimeGraph78", timeGraphList);

    useEffect(() => {
        getUserTimeGraph(uid, graphYear.value)
    }, [graphYear, graphYear.value])



    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleSelectMenu = (e) => {
        setGraphYear(e)
        setAnchorEl(null);
    }

    const minimalTheme = {
        light: ['#3d3d3d', '#064525', '#006d32', '#26a641', '#39d353'],
        dark: ['#3d3d3d', '#064525', '#006d32', '#26a641', '#39d353'],
    }


    return (
        <ProfileCard sx={{ mt: 2, flexDirection: 'column' }} >
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography fontSize='0.75rem' color='#eff2f699'>Time Graph</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'end' }} >
                    <CBButton
                        variant="text"
                        size="small"
                        onClick={handleOpenMenu} sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                        {graphYear?.label}
                        <KeyboardArrowDownIcon fontSize='small' />
                    </CBButton>
                    <CBMenu
                        target={anchorEl}
                        options={graphYearOptions}
                        onCloseMenu={handleCloseMenu}
                        onItemSelect={(e) => handleSelectMenu(e)}
                        checkIcon
                        selectedOption={graphYear}
                    />
                </Box>
            </Stack>
            <Box sx={{ overflow: 'auto' }}>
                <ActivityCalendar
                    style={{
                        marginLeft: 'auto', marginRight: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 8,
                        width: '100%',
                        minWidth: '750px',
                        maxWidth: '100%',
                    }}
                    loading={!timeGraphList || timeGraphList?.length == 0}
                    theme={minimalTheme}
                    data={timeGraphList}
                    renderBlock={(block, activity) => (
                        <Tooltip title={`${activity.count} submissions on ${activity.date}`}>
                            {block}
                        </Tooltip>
                    )}
                    labels={{
                        totalCount: "{{count}} submissions in {{year}}",
                    }
                    }
                />
            </Box>
        </ProfileCard>
    )
}



export default TimeGraph