import Axios from "axios";
import { store } from "../../common/redux/store";
import { GET_USER_METADATA, GET_USER_RECENT_SUBMISSIONS, GET_USER_TIME_GRAPH, POST_USER_GEO_INFO, UPDATE_USER_STATUS } from "../constants";
import { setError } from "../redux/error";
import { setUserMetadata, setUserRecentSubmissions, setUserStatus, setUserTimeGraph } from "../redux/user";

const headers =  {
    'Content-Type': "application/json; charset=utf-8",
    "accept": "application/json"
}


export const updateUserStatus = (body) => {
    console.log("Updating user status to ",body.status);
    
    const targetUrl = UPDATE_USER_STATUS

    const payload = {
        "status": body.status,
    }

    const requestOptions = {
        method: 'POST',
        headers: headers
    };

    Axios.post(targetUrl, payload, requestOptions)
        .then(
            (result) => {
                console.log(result);
                if (result.status == 200) {
                    console.log("updateUserStatus result : ",result);
                    store.dispatch(setUserStatus(result.data.status))
                } else {
                    console.log("User could not be saved");
                }

            },
            (error) => {
                console.log("Something went wrong");
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: error?.response?.status == 404 ? true : false,
                }));
            }
        )
}


export const getUserMetadata = (userId) => {
    
    const targetUrl = GET_USER_METADATA + "/" + userId
    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    Axios.get(targetUrl, requestOptions)
        .then(
            (result) => {
                console.log("getUserMetadata", result);
                if (result.status == 200) {
                    store.dispatch(setUserMetadata(result.data));
                }
                else console.error("getUserMetadata : Something went wrong");
            },
            (error) => {
                console.log("Error while getUserMetadata : ", error);
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: false,
                }));
            }
        )

}


export const postUserGeoInfo = (data) => {
    console.log("postUserGeoInfo ",data);
    
    const targetUrl = POST_USER_GEO_INFO

    const payload = {
        "continentCode": data?.continent_code,
        "countryCode": data?.country_code,
        "countryName": data?.country_name,
        "region": data?.region,
        "city": data?.city,
        "regionCode": data?.region_code,
        "postal": data?.postal
      };

    const requestOptions = {
        method: 'POST',
        headers: headers
    };

    Axios.post(targetUrl, payload, requestOptions)
        .then(
            (result) => {
                console.log(result);
                if (result.status == 200) {
                    console.log("postUserGeoInfo result : ",result);
                } else {
                    console.log("postUserGeoInfo saved");
                }

            },
            (error) => {
                console.log("Something went wrong");
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: error?.response?.status == 404 ? true : false,
                }));
            }
        ).catch((error) => {
            console.log("postUserGeoInfo : ",error);
            console.log("Something went wrong");
            store.dispatch(setError({
                message: error?.response?.data?.message,
                httpStatus: error?.response?.status,
                code: error?.response?.data?.code,
                shouldRedirect: error?.response?.status == 404 ? true : false,
            }));
        });
}


export const getGeoInfo = () => {
    Axios.get('https://ipapi.co/json/').then((response) => {
        let data = response.data;
        console.log("getGeoInfo : ",data);
        postUserGeoInfo(data)
    }).catch((error) => {
        console.log("getGeoInfo : ",error);
    });
};


export const getUserTimeGraph = (userId,year) => {
    
    const targetUrl = GET_USER_TIME_GRAPH + "/" + userId +"/"+year
    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    Axios.get(targetUrl, requestOptions)
        .then(
            (result) => {
                console.log("getUserTimeGraph", result.data);
                if (result.status == 200) {
                    store.dispatch(setUserTimeGraph(result.data));
                }
                else console.error("getUserTimeGraph : Something went wrong");
            },
            (error) => {
                console.log("Error while getUserTimeGraph : ", error);
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: false,
                }));
            }
        )

}

export const getUserRecentSubmissions = (userId) => {
    
    const targetUrl = GET_USER_RECENT_SUBMISSIONS + "/" + userId
    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    Axios.get(targetUrl, requestOptions)
        .then(
            (result) => {
                console.log("getUserRecentSubmissions", result.data);
                if (result.status == 200) {
                    store.dispatch(setUserRecentSubmissions(result.data));
                }
                else console.error("getUserRecentSubmissions : Something went wrong");
            },
            (error) => {
                console.log("Error while getUserRecentSubmissions : ", error);
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: false,
                }));
            }
        )

}