import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css'
import theme from './theme';
import { AuthProvider } from './common/context/AuthContext';
import ReactGA from 'react-ga';
import AdminApp from './adminApp/AdminApp';
import Notification from './common/components/Notification';
import CBApp from './cbApp/CBApp';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import WebSocketProvider, { WebSocketContext } from './cbApp/websocket/websocket';
import PostHogPageviewTracker from './common/context/PostHogPageviewTracker';



function App() {

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || 'G-CLP3T1N0L6');
    ReactGA.pageview(window?.location?.pathname + window.location.search);
  }, [])


  return (
      <AuthProvider>
        <WebSocketProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme={true} />
          <div className="App">
            <Router>
            <PostHogPageviewTracker/>
              <Routes>
                <Route path='/admin/*' element={<AdminApp/>} />
                <Route path='/*' element={<CBApp/>} />
              </Routes>
              <Notification />
            </Router>
          </div>
        </ThemeProvider>
        </WebSocketProvider>
      </AuthProvider>
  );
}

export default App;