import { Box } from "@mui/material"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import CBCurvedTabSection from "../../../../common/components/CBCurvedTabSection"
import { getUserRecentSubmissions } from "../../../apis/userApis"
import RecentSubmissionTab from "./recentSubmissionTab"


const RecentSubmissions = () => {

    const { uid } = useParams();
    const {userRecentSubmissions } = useSelector(state => state.user)
    useEffect(() => {
        getUserRecentSubmissions(uid)
    },[])

    const participationTabs = [
        {
            key: "Submissions",
            node: <RecentSubmissionTab userRecentSubmissions={userRecentSubmissions}/>
        }
    ]


    return(
        <Box sx={{borderRadius:2,mt:1.75,mb:10}}>
            <CBCurvedTabSection tabs={participationTabs} initalTabIndex={0} />
        </Box>
    )

}


export default RecentSubmissions;