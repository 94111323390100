import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userStatus: null,
    userMetadata: null,
    userTimeGraph: null,
    userRecentSubmissions: null,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserStatus: (state, action) => {
            state.userStatus = action.payload
        },
        setUserMetadata: (state,action) => {
            state.userMetadata = action.payload
        },
        setUserTimeGraph: (state,action) => {
            state.userTimeGraph = action.payload
        },
        setUserRecentSubmissions: (state,action) => {
            state.userRecentSubmissions = action.payload
        }
    },
})

export const { setUserStatus,setUserMetadata,setUserTimeGraph,setUserRecentSubmissions } = userSlice.actions

export default userSlice.reducer