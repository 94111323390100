import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#10a0a0',
        },
        secondary: {
            main: '#3e3e3e',
        },
        background: {
            paper: '#1d1d1d',
            midlight: '#2d2d2d'
        },
        text:{
            primary: '#f6f6f6'
        }
    },
    typography: {
        h7: {
            fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto…e Color Emoji","Segoe UI Emoji","Segoe UI Symbol"', 
            fontWeight: 500, 
            fontSize: '1.25rem', 
            lineHeight: 1.6
          },
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
      },
});

console.log(theme);

export default theme;
