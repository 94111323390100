import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box } from '@mui/material';
import { useState } from 'react';
import CBButton from '../../common/components/CBButton';
import CBMenu, { CBMenuOption } from '../../common/components/CBMenu';


interface DifficultyMenuProps {
    difficultyMap: CBMenuOption[];
    difficultyLevel: CBMenuOption;
    setDifficultyLevel: (option : CBMenuOption) => void; 
}


const DifficultyMenu : React.FC<DifficultyMenuProps> = ({difficultyMap,difficultyLevel,setDifficultyLevel}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    

    const handleOpenMenu = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleSelectDifficulty = (option : CBMenuOption) => {
        setDifficultyLevel(option)
        handleCloseMenu();
    };

    return (
        <Box>
            <CBButton
                variant="text"
                onClick={handleOpenMenu} sx={{ ml:2,color: '#f6f6f6' }}>
                {difficultyLevel?.label}
                <KeyboardArrowDownIcon/>
            </CBButton>
            <CBMenu
                target={anchorEl}
                options={difficultyMap}
                onCloseMenu={handleCloseMenu}
                onItemSelect={handleSelectDifficulty}
                checkIcon
                selectedOption={difficultyLevel}
            />
        </Box>
    );
};

export default DifficultyMenu;
