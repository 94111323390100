import CBCurvedTabSection from '../../../../common/components/CBCurvedTabSection';
import WelcomeTabPanel from './welcomeTabPanel';

function WelcomeContentSection() {
    
    const tabs = [{key:'Introduction 📢',node:<WelcomeTabPanel/>}]

    return (
        <CBCurvedTabSection tabs={tabs} initalTabIndex={0}/>
    )
}

export default WelcomeContentSection
