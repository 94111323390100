import { AppBar, Box, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { ReactComponent as CodeBattleIcon } from "../assets/cb_full_logo.svg";
import { useAuth } from "../context/AuthContext";
import { NavButton, NavLogin, OPTION_ROUTES, handleOnRouteClick } from "../utils/NavOptionRoutes";
import AppDrawer from "./AppDrawer";
import Login from "./login";

export default function NavBar({ sx }) {
  const [showLogin, setShowLogin] = useState(false);
  const { currentUser } = useAuth();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const onLoginClick = () => {
    setShowLogin(true);
  };

  const handleCloseLogin = () => {
    setShowLogin(false);
  };


  return (
    <>
      <Login showLogin={showLogin} handleCloseLogin={handleCloseLogin} />

      <AppBar sx={{ maxHeight: "56px", bgcolor: "#282828", ...sx }} position="static">
        <Toolbar
          variant="dense"
          sx={{
            height: "50px",
            bgcolor: "#282828",
            display: "flex",
            justifyItems: "center",
            justifyContent: "space-between",
            ...sx,
          }}
        >
          <CodeBattleIcon onClick={() => window.location?.replace("/")} style={{ cursor: 'pointer' }} />

          {isMobile ? (
            <AppDrawer setShowLogin={setShowLogin} />
          ) : (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
              {OPTION_ROUTES.map((route) => (
                <div onClick={() => handleOnRouteClick(route.path,route.external)}>
                  <NavButton title={route.name} color="inherit" isMobile={isMobile}/>
                </div>
              ))}

              <NavLogin currentUser={currentUser} onLoginClick={onLoginClick} isMobile={isMobile}/>
              
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}
