import { Routes, Route } from "react-router-dom";
import ErrorPage from '../../common/pages/errorPage';
import NavBar from '../../common/components/NavBar';
import EditorPage from './editorPage';
import AboutPage from "./aboutPage";
import ContestListPage from "./contestListPage";
import ProfilePage from "./profilePage";
import LeaderBoardPage from "./leaderboardPage";

function MainPages() {

  return (
    <div>
      <NavBar />
      <Routes>
        <Route path="/editor/:contest_id" element={<EditorPage/>} />
        <Route path='/error' element={<ErrorPage/>} />
        <Route path='about' element={<AboutPage/>} />
        <Route path='/rooms' element={<ContestListPage/>} />
        <Route path='/leaderboard' element={<LeaderBoardPage/>} />
        <Route path='/profile/:uid' element={<ProfilePage/>} />
        <Route errorElement={<ErrorPage/>} />
      </Routes>
    </div>
  );
}

export default MainPages;