import BoltIcon from '@mui/icons-material/Bolt'
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'
import { Box, Stack, Typography } from "@mui/material"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useAuth } from "../../../../common/context/AuthContext"
import { getUserMetadata } from "../../../apis/userApis"
import { ReactComponent as ScoreIcon } from '../../../assets/score_profile.svg'
import ProfileCard from "./profileCard"
import UserMetaDataCard from "./userMetaDataCard"
import UserMetaDataLoading from "./userMetaDataLoading"
import UserMetaDataProgress from "./userMetaDataProgress"

const UserMetaData = () => {

    const { uid } = useParams();
    const { userMetadata } = useSelector(state => state.user)
    const { currentUser } = useAuth();

    useEffect(() => {
        getUserMetadata(uid)
    }, [])


    console.log("Profile90", userMetadata);

    const totalSolved = (userMetadata?.problemSolvedCount?.EASY || 0) + (userMetadata?.problemSolvedCount?.MEDIUM || 0) + (userMetadata?.problemSolvedCount?.HARD || 0)

    return (
        <div> {!userMetadata ? <UserMetaDataLoading /> :
            <Box sx={{
                display: 'flex',
                flexDirection: { lg: 'row', md: 'column', sm: 'column', xs: 'column' },
                width: '100%'
            }}>
                <ProfileCard sx={{ flexDirection: { lg: 'row', md: 'row', sm: 'row', xs: 'column' } }}>
                    <Stack direction='column' sx={{ mr: 1 }}>
                        <img src={userMetadata?.user?.photoURL} height='100px' width='100px' style={{ borderRadius: '8px' }} />
                        <Typography fontSize={'1rem'} lineHeight={'1.5rem'} fontWeight='600' sx={{ mt: 1.5 }}>{userMetadata?.user?.displayName}</Typography>
                        {currentUser?.email && currentUser?.uid == userMetadata?.user?.uid && <Typography fontSize='0.75rem' color='#eff2f699'>{currentUser?.email}</Typography>}
                    </Stack>
                    <Stack direction='row' sx={{ mt: { lg: 0, md: 0, sm: 0, xs: 2 }, ml: { lg: 2, md: 2, sm: 0, xs: 0 } }} flex={1}>
                        <Stack direction='column' flex={1} sx={{ mr: 1 }}>
                            <UserMetaDataCard sx={{ mb: 1 }} title={'Rank'} value={userMetadata?.rank}>
                                <MilitaryTechIcon sx={{ color: '#282828', height: '40px', width: '40px' }} />
                            </UserMetaDataCard>

                            <UserMetaDataCard sx={{ mt: 1 }} title={'Streak'} value={userMetadata?.currentStreak} subValue={'days'}>
                                <BoltIcon sx={{ color: '#282828', height: '40px', width: '40px' }} />
                            </UserMetaDataCard>
                        </Stack>
                        <Stack direction='column' flex={1} sx={{ ml: 1 }}>
                            <UserMetaDataCard sx={{ mb: 1 }} title={'Score'} value={userMetadata?.totalScore}>
                                <ScoreIcon height='40px' width='40px' />
                            </UserMetaDataCard>

                            <UserMetaDataCard sx={{ mt: 1 }} title={'Max Streak'} value={userMetadata?.maxStreak} subValue={'days'}>
                                <LocalFireDepartmentIcon sx={{ color: '#282828', height: '40px', width: '40px' }} />
                            </UserMetaDataCard>
                        </Stack>
                    </Stack>
                </ProfileCard>

                <Box sx={{ p: 1 }} />

                <ProfileCard sx={{ flexDirection: 'row' }}>
                    <Box flex={1}>
                        <Box sx={{display:'flex',justifyContent:'space-between'}}>
                        <Typography fontSize='0.75rem' color='#eff2f699'>Solved Problems</Typography>
                        <Typography fontSize='0.75rem' color='#eff2f699'>Total Solved :{' '}<span style={{fontWeight:'bold',color:'white'}}>{totalSolved}</span></Typography>
                        </Box>
                        <Stack>
                            <UserMetaDataProgress title={'Easy'} solved={userMetadata?.problemSolvedCount?.EASY || 0} total={totalSolved} level={'EASY'} />
                            <UserMetaDataProgress title={'Medium'} solved={userMetadata?.problemSolvedCount?.MEDIUM || 0} total={totalSolved} level={'MEDIUM'} />
                            <UserMetaDataProgress title={'Hard'} solved={userMetadata?.problemSolvedCount?.HARD || 0} total={totalSolved} level={'HARD'} />
                        </Stack>
                    </Box>
                </ProfileCard>
            </Box>}</div>
    )
}


export default UserMetaData