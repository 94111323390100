import MenuIcon from "@mui/icons-material/Menu";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  useMediaQuery
} from "@mui/material";
import { useState } from "react";
import { useAuth } from "../context/AuthContext";
import { DRAWER_OPTION_ROUTES, NavButton, NavLogin, handleOnRouteClick } from "../utils/NavOptionRoutes";
import theme from "../../theme";


function AppDrawer({ setShowLogin }) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { currentUser } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };


  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
          sx: {
            backgroundColor: "#1a1a1a",
            minWidth: '180px',
          },
        }}
      >
        <List>
          <ListItem
            // onClick={() => setOpenDrawer(false)}
            sx={{ paddingBottom: 2, borderBottom: "1px solid #fff" }}
          >
            <NavLogin currentUser={currentUser} onLoginClick={() => setShowLogin(true)} isMobile={isMobile}/>
          </ListItem>

          {DRAWER_OPTION_ROUTES.map((route) => (
            <ListItem
              key={route.name}
              onClick={() => handleOnRouteClick(route.path,route.external)}
              sx={{ marginTop: 0.5 }}>
              <ListItemText>
                <NavButton title={route.name} color={route.color} isMobile={isMobile}/>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Drawer>

      <IconButton onClick={toggleDrawer}>
        <MenuIcon />
      </IconButton>
    </>
  );
}

export default AppDrawer;

