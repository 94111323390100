import Axios from "axios";
import { setError } from "../../cbApp/redux/error";
import { setUserStatus } from "../../cbApp/redux/user";
import { SAVE_USER } from "../constants";
import { store } from "../redux/store";

const headers =  {
    'Content-Type': "application/json; charset=utf-8",
    "accept": "application/json"
}

export const saveUser = (body) => {
    
    const targetUrl = SAVE_USER

    const payload = {
        "uid": body.uid,
        "displayName": body.displayName,
        "email": body.email,
        "phoneNumber": body.phoneNumber,
        "photoURL": body.photoURL
    }

    const requestOptions = {
        method: 'POST',
        headers: headers
    };

    Axios.post(targetUrl, payload, requestOptions)
        .then(
            (result) => {
                console.log(result);
                if (result.status == 201) {
                    console.log("saveUser result : ",result);

                    if(result?.data?.customToken){
                        Axios.defaults.headers.common['Authorization'] = `Bearer ${result?.data?.customToken}`;
                        localStorage.setItem('access_token',result?.data?.customToken)
                    }

                    store.dispatch(setUserStatus(result.data.status))
                    // window.location.reload()
                } else {
                    console.log("User could not be saved");
                }

            },
            (error) => {
                console.log("Something went wrong");
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: error?.response?.status == 404 ? true : false,
                }));
            }
        )
}