import data from '@emoji-mart/data';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Link, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import { init } from 'emoji-mart';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { TableVirtuoso } from 'react-virtuoso';
import CBLoader from '../../common/components/CBLoader';
import DifficultyLevelChip from '../../common/components/DifficultyLevelChip';
import { DIFFICULTY_LEVELS, DIFFICULTY_SMALL } from '../../common/constants';
import { useAuth } from '../../common/context/AuthContext';
import { PATH_PROFILE, handleOnRouteClick } from '../../common/utils/NavOptionRoutes';
import { getLeaderboardUsers } from '../apis/leaderboardApis';
import LinkTypography from '../components/common/LinkTypography';

polyfillCountryFlagEmojis();

const LeaderboardPage = () => {
    const { leaderboardUsers } = useSelector(state => state.leaderboard);
    const { currentUser, loading } = useAuth();
    const parentRef = useRef(null);
    init({ data });

    useEffect(() => {
        getLeaderboardUsers();
    }, []);

    let updatedLeaderboard = leaderboardUsers;
    const userMetadata = currentUser && currentUser?.uid && updatedLeaderboard?.find(user => user?.user?.uid === currentUser?.uid);

    if (userMetadata && userMetadata?.rank > 3) {
        updatedLeaderboard = userMetadata != null ? [userMetadata].concat(leaderboardUsers) : leaderboardUsers;
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        '&:first-child': {
            borderTopLeftRadius: "16px",
            borderBottomLeftRadius: '16px',
        },
        '&:last-child': {
            borderTopRightRadius: "16px",
            borderBottomRightRadius: "16px"
        },
    }));

    const getRankColor = (rank) => {
        if (rank === 1) {
            return '🥇';
        } else if (rank === 2) {
            return '🥈';
        } else if (rank === 3) {
            return '🥉';
        } else {
            return rank; // Use the default color
        }
    };

    const smallCountryCode = (countryCode) => {
        return countryCode.toLowerCase();
    };

    const handleUserClick = (uid) => {
        handleOnRouteClick(PATH_PROFILE + uid);
    };

    const VirtuosoTableComponents = {
        Table: (props) => (
            <Table
                {...props}
                sx={{
                    mt: 4,
                    borderSpacing: '0 8px',
                    borderCollapse: 'separate',
                    '& .MuiTableCell-root': {
                        height: '64px',
                        border: 'none',
                        padding: '12px'
                    },
                    '& .MuiTableCell-root:first-child': {
                        borderTopLeftRadius: "16px",
                        borderBottomLeftRadius: '16px',
                    },
                    '& .MuiTableCell-root:last-child': {
                        borderTopRightRadius: "16px",
                        borderBottomRightRadius: "16px"
                    },
                    px: {
                        lg: 20,
                        md: 5,
                        sm: 1,
                        xs: 1,
                    },
                }}
                style={{ borderSpacing: '0 8px' }}
            />
        ),
        TableHead: React.forwardRef((props, ref) => (
            <TableHead
                {...props}
                ref={ref}
                sx={{
                    bgcolor: '#1a1a1a',
                    position: 'sticky',
                    top: '0px',
                    zIndex: 2,
                    borderRadius: 0
                }}
            />
        )),
        TableRow: ({ item: _item, ...props }) => (
            <TableRow
                {...props}
                sx={{
                    bgcolor: currentUser?.uid === _item?.user?.uid ? '#404040' : '#282828',
                    width: '100%'
                }}
            />
        ),
        TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    };

    const headers = [
        'Rank 🏅',
        'Username 👨🏽‍💻',
        'Country 🌐',
        'Points 🎯',
    ];

    const fixedHeaderContent = () => (
        <TableRow align='center' sx={{ height: '64px', p: 2 }}>
            {headers.map((header) => (
                <Box
                    key={header}
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        border: 'none',
                        width: '25%',
                        borderRadius: 0,
                        display: 'table-cell',
                        verticalAlign: 'middle'
                    }}
                >
                    {header}
                </Box>
            ))}
        </TableRow>
    );

    const ProblemSolvedCountToolTip = ({ user }) => {
        const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);
        return (
            <Tooltip
                open={tooltipIsOpen}
                onOpen={() => setTooltipIsOpen(true)}
                onClose={() => setTooltipIsOpen(false)}
                PopperProps={{
                    sx: {
                        '& .MuiTooltip-arrow': {
                            color: '#1d1d1d'
                        },
                        '& .MuiTooltip-tooltip': {
                            backgroundColor: '#1d1d1d',
                        },
                    }
                }}
                placement='right'
                arrow
                title={
                    <Box>
                        {DIFFICULTY_LEVELS.map(val => (
                            <Box key={val} sx={{ m: 1 }}>
                                <DifficultyLevelChip
                                    difficultyLevel={val}
                                    label={`${DIFFICULTY_SMALL[val]} : ${user?.problemSolvedCount?.[val] || 0}`}
                                />
                            </Box>
                        ))}
                    </Box>
                }
            >
                <InfoOutlinedIcon
                    fontSize='small'
                    size='small'
                    sx={{ color: '#8a8a8a', ml: { sm: 2, xs: 1 } }}
                    onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
                />
            </Tooltip>
        );
    };

    const rowContent = (_index, user) => (
        <>
            <StyledTableCell align='center'>
                {user?.rank <= 3 ? (
                    <Typography variant="h6" fontWeight='600'> {getRankColor(user?.rank)}</Typography>
                ) : (
                    <Typography color={'text.secondary'} variant="body2" fontWeight='600'> {user?.rank}</Typography>
                )}
            </StyledTableCell>
            <StyledTableCell align='center'>
                <LinkTypography color={'text.secondary'} component="a" href={`${PATH_PROFILE + user?.user?.uid}`}>
                    {user?.user?.displayName}
                </LinkTypography>
            </StyledTableCell>
            <StyledTableCell align='center'>
                {user?.countryCode ? (
                    <em-emoji style={{ fontSize: "1.5rem" }} shortcodes={`:flag-${smallCountryCode(user?.countryCode)}:`} />
                ) : (
                    "-"
                )}
            </StyledTableCell>
            <StyledTableCell align='center'>
                <Typography color={'text.secondary'} variant="body2">
                    {user?.totalScore}
                    <ProblemSolvedCountToolTip user={user} />
                </Typography>
            </StyledTableCell>
        </>
    );

    return (
        <Box>
            {!updatedLeaderboard || updatedLeaderboard.length === 0 ? (
                <CBLoader />
            ) : (
                <Box
                    sx={{
                        overflow: 'auto',
                        p: 0,
                        height: 'calc(100vh - 54px)',
                        bgcolor: '#1a1a1a'
                    }}
                    ref={parentRef}
                >
                    <TableVirtuoso
                        data={updatedLeaderboard}
                        components={VirtuosoTableComponents}
                        fixedHeaderContent={fixedHeaderContent}
                        itemContent={rowContent}
                        customScrollParent={parentRef.current}
                    />
                </Box>
            )}
        </Box>
    );
};

export default LeaderboardPage;
