import Axios from "axios";
import { setDefaultCodes, setEditorLoading } from "../redux/editor";
import { setError } from "../redux/error";
import { } from "../redux/question";
import { store } from '../../common/redux/store';
import { GET_PROBLEM_METADATA } from "../constants";

const headers =  {
    'Content-Type': "application/json; charset=utf-8",
    "accept": "application/json"
}

export const getProblemMetadata = (data) => {

    if(!data?.languageId) return;

    const contestId = store.getState().contest.id;
    if(!contestId){
        console.log("Contest ID not present");
        return;
    }

    const targetUrl = `${GET_PROBLEM_METADATA}/${contestId}/${data?.languageId}`

    const requestOptions = {
        headers: headers,
    };

    console.log("Fetching getProblemMetadata for : ",data.languageId);

    store.dispatch(setEditorLoading(true));

    Axios.get(targetUrl, requestOptions)
        .then(
            (result) => {
                console.log("getProblemMetadata result : ", result);
                if (result.status === 200) {
                    store.dispatch(setDefaultCodes(result.data))
                }
                store.dispatch(setEditorLoading(false));
            },
            (error) => {
                console.log(error);
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: error?.response?.status == 404 ? true : false,
                }));
                store.dispatch(setEditorLoading(false));
            }
        )
}