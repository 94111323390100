import Axios from "axios";
import { resetStateAction } from "../../common/redux/reducer";
import { persistor, store } from '../../common/redux/store';
import { CONTEST_STATUS, CREATE_CONTEST, GET_ALL_CONTESTS, GET_ALL_PUBLISHED_TOPIC_TAGS, JOIN_CONTEST, LEAVE_CONTEST, NEXT_CONTEST, READY_CONTEST } from "../constants";
import { clearContest, setAllContest, setCreateContest, setJoinContest } from "../redux/contest";
import { setEndTime } from "../redux/countdown";
import { setError } from "../redux/error";
import { setIsUserReady, setParticipants, updateParticipantStatus } from "../redux/participant";
import { clearQuestionRedux, setQuestions } from "../redux/question";
import { setUserStatus } from "../redux/user";
import { getContestChat } from "./chatApis";
import { setPublishedTopics, setPublishedTopicsLoading } from "../redux/publishedTopic";

const headers = {
    'Content-Type': "application/json; charset=utf-8",
    "accept": "application/json"
}


export const createContest = (data, currentUser) => {
    if (!currentUser) {
        console.log("User not loggedIn");
        return;
    }
    const targetUrl = CREATE_CONTEST

    const payload = {
        'difficultyLevel': data.difficultyLevel,
        'contestLength': data?.contestLength ?? 0,
        'topics': data?.topics ?? [],
    };

    const requestOptions = {
        method: 'POST',
        headers: headers
    };

    Axios.post(targetUrl, payload, requestOptions)
        .then(
            (result) => {
                console.log(result);
                if (result.status === 201) {
                    store.dispatch(setCreateContest(result.data))
                    window.location?.replace(result?.headers['location'])
                }
            },
            (error) => {
                console.log("Error while createContest : ", error);
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: false,
                }));
            }
        )
}


export const nextContest = (currentUser) => {
    if (!currentUser) {
        console.log("User not loggedIn");
        return;
    }

    const contestId = store.getState().contest.id;
    const difficultyLevel = store.getState().contest.difficultyLevel;
    if (!contestId) {
        console.log("Contest ID not present");
        return;
    }

    const targetUrl = NEXT_CONTEST

    const payload = {
        'id': contestId,
        'difficultyLevel': difficultyLevel
    };

    const requestOptions = {
        method: 'POST',
        headers: headers
    };

    Axios.post(targetUrl, payload, requestOptions)
        .then(
            (result) => {
                console.log(result);
                if (result.status === 201) {
                    persistor.purge().then(() => {
                        console.log("Reset Everything");
                        store.dispatch(resetStateAction());
                    })
                        .catch(() => {
                            console.log("Something went wrong");
                        })
                    store.dispatch(setCreateContest(result.data))
                    window.open(result?.headers['location'], '_blank');
                }
            },
            (error) => {
                console.log(error);
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: error?.response?.status == 404 ? true : false,
                }));
            }
        )
}


export const joinContest = (constestId, currentUser) => {

    if (!constestId) {
        console.log("joinContest constestId : ", constestId);
        return;
    }

    const userAlreadyJoined = store.getState().participant.participants.some(p => p.uid === currentUser.uid)
    // if(userAlreadyJoined) return;

    const targetUrl = JOIN_CONTEST

    const payload = {
        "id": constestId
    }
    const requestOptions = {
        method: 'POST',
        headers: headers
    };

    Axios.post(targetUrl, payload, requestOptions)
        .then(
            (result) => {
                console.log(result);
                if (result.status == 200) {
                    console.log("YAAAH!!!! joined the contest");
                    store.dispatch(setJoinContest(result.data));
                    const user = result.data.participants.find(p => p.uid === currentUser.uid);
                    store.dispatch(setUserStatus(user.status))
                    if (result.data.participants) store.dispatch(setParticipants([...result.data.participants]));
                    if (result.data.questions) store.dispatch(setQuestions(result.data.questions))
                    console.log("Dekhte hai");
                    console.log(result.data.status);
                    if (result.data.status === CONTEST_STATUS.STARTING || result.data.status === CONTEST_STATUS.STARTED) {
                        console.log(result.data.status);
                        if (result.data.timeLeft != 0) {
                            console.log(result.data.timeLeft);
                            store.dispatch(setEndTime(Date.now() + result.data.timeLeft))
                        }
                    }
                    getContestChat()
                } else {
                    console.log("Something went wrong");
                    store.dispatch(setError(result));
                }

            },
            (error) => {
                console.log("Something went wrong : ", error);
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: error?.response?.status == 404 ? true : false,
                }));
            }
        )
}


export const readyUserContest = (constestId) => {

    if (!constestId) {
        console.log("readyUserContest constestId : ", constestId);
    }

    const targetUrl = READY_CONTEST

    const payload = {
        "id": constestId
    }
    const requestOptions = {
        method: 'POST',
        headers: headers
    };

    Axios.post(targetUrl, payload, requestOptions)
        .then(
            (result) => {
                console.log(result);
                if (result.status == 200) {
                    console.log("User is ready to start the contest");
                    store.dispatch(updateParticipantStatus(result.data));
                    store.dispatch(setIsUserReady(true));
                } else {
                    console.log("Something went wrong");
                }

            },
            (error) => {
                console.log("USER not logged in");
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: error?.response?.status == 404 ? true : false,
                }));
            }
        )
}


export const leaveContest = (constestId) => {

    if (!constestId) {
        console.log("leaveContest constestId : ", constestId);
        return;
    }

    const targetUrl = LEAVE_CONTEST

    const payload = {
        "id": constestId
    }

    const requestOptions = {
        headers: headers
    };

    Axios.post(targetUrl, payload, requestOptions)
        .then(
            (result) => {
                console.log(result);
                if (result.status == 200) {
                    store.dispatch(clearContest());
                    store.dispatch(clearQuestionRedux());
                    console.log("Oh no!!!! left the contest");
                    window.location.href = "/";
                } else {
                    console.log("Something went wrong");
                }

            },
            (error) => {
                console.log("USER not logged in");
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: error?.response?.status == 404 ? true : false,
                }));
            }
        )
}

export const getContests = () => {
    const targetUrl = GET_ALL_CONTESTS
    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    Axios.get(targetUrl, requestOptions)
        .then(
            (result) => {
                console.log("getContests", result);
                if (result.status == 200) {
                    const allContest = customContestSort(result.data)
                    store.dispatch(setAllContest(allContest));
                }
                else console.error("Something went wrong");
            },
            (error) => {
                console.log("Error while getContests : ", error);
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: false,
                }));
            }
        )
}

const customContestSort = (allContest) => {
    const statusOrder = {
        STARTING: 1,
        STARTED: 2,
        CREATED: 3,
        ENDED: 4,
    };

    allContest = allContest.filter((contest) => {
        if(contest.status === CONTEST_STATUS.STARTED){
            return contest.timeLeft > 0
        } else {
            return true
        }
    });

    allContest.sort((a, b) => {
        // Sort based on status
        const statusA = a.status;
        const statusB = b.status;
        const statusOrderA = statusOrder[statusA] || 0;
        const statusOrderB = statusOrder[statusB] || 0;

        if (statusOrderA !== statusOrderB) {
            return statusOrderA - statusOrderB;
        }

        // Sort based on lastUpdatedAt if status is the same
        const lastUpdatedAtA = new Date(a.lastUpdatedAt);
        const lastUpdatedAtB = new Date(b.lastUpdatedAt);

        return lastUpdatedAtB - lastUpdatedAtA;
    });

    if (allContest?.length > 20) {
        return allContest?.slice(0, 20); // Return at most 20 contests
    }

    return allContest;
}


export const getAllPublishedTopicTags = () => {
    store.dispatch(setPublishedTopicsLoading(true))
    const targetUrl = GET_ALL_PUBLISHED_TOPIC_TAGS
    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    Axios.get(targetUrl, requestOptions)
        .then(
            (result) => {
                console.log("getAllTopicTags", result);
                store.dispatch(setPublishedTopics(result.data));
                store.dispatch(setPublishedTopicsLoading(false))
            },
            (error) => {
                console.log("Error while getAllTopicTags : ", error);
                store.dispatch(setPublishedTopicsLoading(false))
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: false,
                }));
            }
        )
}
