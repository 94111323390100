import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Split from 'react-split';
import CBLoader from '../../common/components/CBLoader';
import { useAuth } from '../../common/context/AuthContext';
import { joinContest } from '../apis/contestApis';
import InvitePopup from '../components/InvitePopup';
import ContentSection from '../components/sections/contentSections/contentSection';
import EditorSection from '../components/sections/editorSections/editorSection';
import LobbySection from '../components/sections/lobbySections/lobbySection';
import Login from "../../common/components/login";
import WebsocketInit from '../websocket/websocketInit';


const EditorPage = () => {
    const { contest_id } = useParams();
    const [showLogin, setShowLogin] = useState(false)
    const [showInvitePopup, setShowInvitePopup] = useState(false)
    const [invitePopupCounter, setInvitePopupCounter] = useState(0)
    const { currentUser, loading } = useAuth();

    useEffect(() => {
        if (!loading && !currentUser) {
          setShowLogin(true);
        } else if(!loading && currentUser) {
          setShowLogin(false);
          console.log("EditorPage contest_id", contest_id);
          joinContest(contest_id, currentUser);
        }
      }, [loading, currentUser]);
      
      useEffect(() => {
        if (currentUser && invitePopupCounter === 0) {
          setShowInvitePopup(true);
          setInvitePopupCounter(prevCounter => prevCounter + 1);
        }
      }, [currentUser, invitePopupCounter]);


      console.log("LobbySection : ",LobbySection);

    return (
        <div>
            <Login showLogin={showLogin} />
            <InvitePopup showInvitePopup={showInvitePopup} setShowInvitePopup={setShowInvitePopup} />
            {loading ?
                <CBLoader />
                :
                <div>
                    <WebsocketInit contest_id={contest_id} currentUser={currentUser}/>
                    <Split minSize={300} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#1a1a1a' }} sizes={[27, 46, 27]} gutterSize={8}>
                        <ContentSection />
                        <EditorSection />
                        <LobbySection contest_id={contest_id}/>
                    </Split>
                </div>
            }
        </div>
    )
}

export default EditorPage;
