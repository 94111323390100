import { useSelector } from 'react-redux';
import ProblemContentSection from './problemContentSection';
import WelcomeContentSection from './welcomeContentSection';


const ContentSection = () => {
    const {questions} = useSelector(state => state.question)

    const defineContentSection = () => {
        console.log("ContentSection :",questions);
        if(!questions || questions.length === 0) return <WelcomeContentSection/>
        return <ProblemContentSection/>
    }

    return (
        <div>
            {defineContentSection()}
        </div>
    )
}
export default ContentSection;