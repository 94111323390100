import { Badge, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import RecentSubmissionsLoading from "./recentSubmissionLoading";

const RecentSubmissionTab = ({userRecentSubmissions}) => {

    const getStatus = (statusId,statusDesc) => {
        if(statusId === 3){
            return <Badge variant="dot" color="success" sx={{
                '& .MuiBadge-badge': {
                    right: '100%',
                    top: '50%',
                  },}}><Typography color={'text.secondary'} sx={{paddingLeft:2}}>{statusDesc}</Typography></Badge>
        } else {
            return <Badge variant="dot" color="error" sx={{
                '& .MuiBadge-badge': {
                    right: '100%',
                    top: '50%',
                  },}}><Typography color={'text.secondary'} sx={{paddingLeft:2}}>{statusDesc}</Typography></Badge>
        }
        return statusDesc
    }


    return <Table sx={{
        '& .MuiTableCell-root': {
            border:'none'
        }
        }}>

        <TableHead>
            <TableRow>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>Problem Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>Date</TableCell>
            </TableRow>

        </TableHead>
        <TableBody>
            {userRecentSubmissions? userRecentSubmissions.map((submission, index) => (
                <TableRow key={submission.id} sx={{ 
                    bgcolor: index % 2 == 0 && '#3e3e3e', 
                }}>
                    <TableCell>
                        <Typography variant="body2">{index + 1}.  &nbsp; {submission.problemTitle}</Typography>
                    </TableCell>
                    
                    <TableCell>{getStatus(submission?.status?.id,submission?.status?.description)}</TableCell>
                    {/* <TableCell><Typography variant="body2">{submission?.status?.description}</Typography></TableCell> */}
                    <TableCell><Typography color={'text.secondary'} variant="body2">{submission?.date}</Typography></TableCell>
                </TableRow>
            )): (
                <TableRow>
                    <RecentSubmissionsLoading />
                    <RecentSubmissionsLoading />
                    <RecentSubmissionsLoading />
                </TableRow>
                )
            }
        </TableBody>
    </Table>
}

export default RecentSubmissionTab;